<template>
    <div id="date-filter">
        <b-card>
            <b-row>
                <b-col cols="12" sm="9" class="text-left date-filter">
                    <b-row>
                        <b-col cols="12" sm="4">
                            <label for="datepicker-invalid">FROM</label>
                            <flat-pickr v-model="startDate" class="form-control form-control-sm" size="sm"
                                :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }" />
                        </b-col>
                        <b-col cols="12" sm="4">
                            <label for="datepicker-fin">TO</label>
                            <flat-pickr v-model="endDate" class="form-control form-control-sm" size="sm"
                                :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }" />
                        </b-col>
                        <b-col cols="12" sm="4">
                            <label for="datepicker-fin">API</label>
                            <b-form-select v-model="selected" :options="options" size="sm" />
                        </b-col>
                    </b-row>
                </b-col>
                <b-col cols="12" sm="3" class="text-right">
                    <b-button size="sm" variant="success" class="mt-2" v-if="isMobileDevice()" block
                        @click="handleFilterClick">
                        <feather-icon icon="FilterIcon" />
                        Filtrer
                    </b-button>
                    <b-button size="sm" variant="success" class="mt-2" v-else @click="handleFilterClick">
                        <feather-icon icon="FilterIcon" class="mr-50 ml-50" />
                        Filtrer
                    </b-button>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>
  
<script>
import flatPickr from 'vue-flatpickr-component';

export default {
    components: {
        flatPickr,
    },
    data() {
        return {
            startDate: new Date(new Date().getTime() - 30 * 60000),
            endDate: new Date(),
            selected: "All",
            options: [
                "All",
                "Arrival",
                "BillingDocuments",
                "Catalog",
                "Claim",
                "CreditMemoHeader",
                "CreditMemoLine",
                "Distribution",
                "Item",
                "Inventory",
                "InvoiceHeader",
                "InvoiceLine",
                "InventoryByVendor",
                "Novelty",
                "OrderHeader",
                "OrderLine",
                "Promotion",
                "ReturnHeader",
                "ReturnLine",
                "SalesOrderHeader",
                "SalesOrderLine",
                "SalesReturnHeader",
                "SalesReturnLine",
                "SalesItemLedgerEntry",
                "ShipmentHeader",
                "ShipmentLine",
                "SubstitutionItems",
                "ValidatedInvoiceHeader",
                "ValidatedInvoiceLine",
                "ValidatedCreditMemoHeader",
                "ValidatedCreditMemoLine",
                "Vendor",
                "VendorItemLedgerEntry"
            ],
        };
    },
    methods: {
        isMobileDevice() {
            return window.innerWidth <= 769;
        },
        handleFilterClick() {
            this.$emit('filterClicked', { sDate: this.startDate, eDate: this.endDate, api: this.selected });
        },
    },
};
</script>
  
<style lang="scss">
@media (max-width: 769px) {
    .date-filter {
        padding-top: 1rem;
    }
}
</style>
  
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>  