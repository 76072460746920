<template>
    <div id="badge-component">
        <b-badge pill v-if="params.colDef.field == 'method'"
            :variant="params.data.method == 'GET' ? 'primary' : params.data.method == 'POST' ? 'success' : params.data.method == 'PUT' ? 'info' : 'danger'">
            <span>{{ params.data.method }}</span>
        </b-badge>
        <b-badge pill v-else-if="params.colDef.field == 'status'"
            :variant="(params.value == 200 || params.value == 201 || params.value == 204) ? 'success' : 'danger'">
            <span>{{ params.value }}</span>
        </b-badge>
    </div>
</template>
  
<script>

export default {
};
</script>