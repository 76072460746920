<template>
    <div id="proposal-list">

        <b-modal ref="my-modal" scrollable title="Log Details" ok-only ok-title="Annuler" size="xl"
            cancel-variant="outline-secondary">
            <div>
                <h5>ID: {{ selectedLog.id }}</h5>
                <p>Logged At: {{ selectedLog.loggedAt }}</p>
                <p>Method: <b-badge pill
                        :variant="selectedLog.method == 'GET' ? 'primary' : selectedLog.method == 'POST' ? 'success' : selectedLog.method == 'PUT' ? 'info' : 'danger'">
                        <span>{{ selectedLog.method }}</span>
                    </b-badge></p>
                <p>Status: <b-badge pill
                        :variant="(selectedLog.status == 200 || selectedLog.status == 201 || selectedLog.status == 204) ? 'success' : 'danger'">
                        <span>{{ selectedLog.status }}</span>
                    </b-badge></p>
                <p>URL: <b-badge variant="secondary">
                        <span>{{ selectedLog.url }}</span>
                    </b-badge> </p>
                <p>User Account: {{ selectedLog.userAccount }}</p>
                <p>Body:</p>
                <pre>  <code>{{ selectedLog.body }}</code></pre>
                <p>Response:</p>

                <pre>
{{ formatJson(selectedLog.response) }}
                </pre>


            </div>
        </b-modal>

        <b-overlay :show="show" spinner-variant="primary">
            <b-card>

                <ag-grid :withFilters="true" :filterComponent="LogFilters" :agGrid="agGrid" pageTitle="MIDDLEWARE LOGS"
                    @refresh="loadData" @filterClicked="filterClicked" @cellDoubleClicked="showDetails" />

            </b-card>
        </b-overlay>


    </div>
</template>
    
<script>
import AgGrid from "@/views/components/ag-grid/AgGrid.vue"
import ButtonCellRenderer from "@/views/components/ag-grid/ButtonCellRenderer.vue"
import LogFilters from '@/views/supervisor/log/LogFilters'
import BadgeCellRenderer from '@/views/supervisor/log/BadgeCellRenderer';

export default {
    components: {
        AgGrid,
    },
    data() {
        return {
            LogFilters: LogFilters,
            startDate: new Date(new Date().getTime() - 30 * 60000),
            endDate: new Date(),
            selectedApi: "All",
            agGrid: {
                columnDefs: [
                    {
                        headerName: "Logged At",
                        field: "loggedAt",
                        width: 150
                    },
                    {
                        headerName: "API",
                        field: "url",
                        width: 200
                        // valueGetter: function (tr) {
                        //     return tr.data.Posting_Date.substring(0, 10);
                        // },
                    },
                    {
                        headerName: "METHOD",
                        field: "method",
                        width: 70,
                        cellRendererFramework: 'badgeCellRenderer',
                    },
                    {
                        headerName: "STATUS",
                        field: "status",
                        width: 70,
                        cellRendererFramework: 'badgeCellRenderer',
                    },
                    {
                        headerName: "USER",
                        field: "userAccount",
                        width: 100
                    },
                ],
                rows: []
            },
            selectedLog: [],
            show: true,
        }
    },
    created() {
        this.$options.components.buttonCellRenderer = ButtonCellRenderer;
        this.$options.components.badgeCellRenderer = BadgeCellRenderer;
        this.loadData();
    },
    methods: {
        camelToSnakeCase(input) {
            return input.replace(/([A-Z])/g, (match) => `_${match.toLowerCase()}`).replace(/^_/, '');
        },
        formatJson(jsonString) {
            if (jsonString != undefined && jsonString.trim() != "") {
                try {
                    const parsedJson = JSON.parse(jsonString);
                    const formattedJson = JSON.stringify(parsedJson, null, 100);
                    return formattedJson;
                } catch (error) {
                    console.log(error);
                    return jsonString; // Return the original string if it's not a valid JSON
                }
            } else return jsonString;
        },
        filterClicked(obj) {
            this.startDate = obj.sDate;
            this.endDate = obj.eDate;
            this.selectedApi = obj.api;
            this.loadData();
            // console.log("log errors")
            // throw new Error('Sentry Error');
        },
        async showDetails(event) {
            this.show = true;
            let response = await this.$http.get("middleware_log/" + event.data.id).catch(() => this.show = false);
            this.selectedLog = response.data;
            this.show = false;
            this.$refs['my-modal'].show();
        },
        async loadData() {
            console.log(this.camelToSnakeCase(this.selectedApi));
            this.show = true;
            let api = this.selectedApi == "All" ? "All" : this.camelToSnakeCase(this.selectedApi);
            let response = await this.$http.get("middleware_log/" + this.generateDateFilters(), {
                params: {
                    api: api
                }
            });
            this.agGrid.rows = response.data;
            this.show = false;
        },
        generateDateFilters() {
            // Convert start date string to Date and adjust by -1 day
            const startDateObj = new Date(this.startDate);
            startDateObj.setDate(startDateObj.getDate());
            // Convert end date string to Date and adjust by +1 day
            const endDateObj = new Date(this.endDate);
            endDateObj.setDate(endDateObj.getDate());
            // Format adjusted dates in "dd-MM-yyyy - HH:mm:ss" format
            const formattedStartDate = this.formatDate(startDateObj);
            const formattedEndDate = this.formatDate(endDateObj);
            return formattedStartDate + "/" + formattedEndDate;
        },

        formatDate(date) {
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');

            return `${day}-${month}-${year} - ${hours}:${minutes}:${seconds}`;
        },

    }
}
</script>
    
<style lang="scss" scoped>
@media (max-width: 768px) {
    .card {
        background-color: initial;
        box-shadow: initial;
    }

    .card-body {
        padding: 0px;
    }
}
</style>
    